import parse from 'html-react-parser';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { capitalize, capitalizeEachWord, moneyFormatter } from '../../../utils/formatterUtils';
import {
  getCalculatedAmountToDisburse, getPrePaidAmount, getProductName, getVehicleBrand, getVehicleModel, isPrePaid, isProductToPayVehicle,
} from '../../../utils/loanRequestUtils';
import { useAuth } from '../../../contexts/AuthContext';

const StepHeader = ({ loanRequest }) => {
  const { getLoggedUserProductList } = useAuth();

  return (
    <>
      {/* TODO: Falta agregar cliente premium, ver si debe ir aca o en otro lado */}
      <Typography align="left" sx={{ fontWeight: 600 }}>
        {i18n.StepHeader.title}
      </Typography>
      <Grid container mt={2} columnGap={2} rowGap={1}>
        <Grid item md="auto">
          <Typography align="left">
            {parse(fillMessageWith(i18n.StepHeader.idCredit, loanRequest.sappCreditId))}
          </Typography>
        </Grid>
        <Grid item md="auto">
          <Typography align="left">
            {parse(fillMessageWith(i18n.StepHeader.product, capitalize(getProductName(loanRequest, getLoggedUserProductList()))))}
          </Typography>
        </Grid>
        <Grid item md="auto">
          <Typography align="left">
            {parse(fillMessageWith(i18n.StepHeader.customerName, capitalizeEachWord(loanRequest.customerName.toLowerCase())))}
          </Typography>
        </Grid>
        <Grid item md="auto">
          <Typography align="left">
            {parse(fillMessageWith(i18n.StepHeader.customerTaxId, loanRequest.customerId))}
          </Typography>
        </Grid>
        {loanRequest.requestedAmount && (
          <Grid item md="auto">
            <Typography align="left">
              {parse(fillMessageWith(i18n.StepHeader.requestedAmount, moneyFormatter(getCalculatedAmountToDisburse(loanRequest))))}
            </Typography>
          </Grid>
        )}
        {loanRequest.requestedAmount && isPrePaid(loanRequest) && (
          <Grid item md="auto">
            <Typography align="left">
              {parse(fillMessageWith(i18n.StepHeader.cancelAmount, moneyFormatter(getPrePaidAmount(loanRequest))))}
            </Typography>
          </Grid>
        )}
        {loanRequest.requestedAmount && (
          <Grid item md="auto">
            <Typography align="left">
              {parse(fillMessageWith(
                i18n.StepHeader.paymentPlan,
                loanRequest.installmentsNumberSelected,
                moneyFormatter(loanRequest.installmentAmountSelected),
              ))}
            </Typography>
          </Grid>
        )}
        {isProductToPayVehicle(loanRequest) && getVehicleBrand(loanRequest) && (
          <Grid item md="auto">
            <Typography align="left">
              {parse(fillMessageWith(
                i18n.StepHeader.vehicle,
                getVehicleBrand(loanRequest),
                getVehicleModel(loanRequest),
              ))}
            </Typography>
          </Grid>
        )}
        {loanRequest.advancePaymentAmount && (
          <Grid item md="auto">
            <Typography align="left">
              {parse(fillMessageWith(i18n.StepHeader.advanceAmount, moneyFormatter(loanRequest.advancePaymentAmount)))}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

StepHeader.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
};

export default StepHeader;
