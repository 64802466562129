import parse from 'html-react-parser';
import { Box, Grid, Typography } from '@mui/material';
import { Alert, Button, CardContent } from '@doit/pcnt-react-ui-library';
import CachedIcon from '@mui/icons-material/Cached';
import Card from '../../components/commons/Card';
import Loading from '../../components/commons/Loading';
import useInstallmentPlanController from '../../hooks/controllers/useInstallmentPlanController';
import i18n from '../../common/i18n';
import { fillMessageWith } from '../../utils/functionsUtils';
import Divider from '../../components/commons/Divider';
import StepHeader from './components/StepHeader';
import { getAvailableAmount } from '../../utils/loanRequestUtils';
import { moneyFormatter } from '../../utils/formatterUtils';
import { REQUIRED_DOCUMENTS } from '../../constants/imageConstants';
import InstallmentCard from './components/InstallmentCard';

const InstallmentPlanPage = () => {
  const {
    globalLoading,
    loanRequest,
    doReload,
    installments,
    selectedInstallment,
    setSelectedInstallment,
  } = useInstallmentPlanController();

  if (globalLoading) {
    return <Loading />;
  }

  if (loanRequest) {
    return (
      <>
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography align="left" sx={{ fontSize: '24px', fontWeight: 700 }}>
                {parse(fillMessageWith(i18n.InstallmentPlanPage.title, moneyFormatter(getAvailableAmount(loanRequest))))}
              </Typography>
              <Button variant="tertiary" color="grey" endIcon={<CachedIcon />} onClick={doReload}>
                {i18n.InstallmentPlanPage.ctaReload}
              </Button>
            </Box>
          </CardContent>
          <Divider />
          <CardContent>
            <StepHeader loanRequest={loanRequest} />
            <Alert severity="info" title={i18n.InstallmentPlanPage.requiredDocumentation} sx={{ marginTop: '32px', width: 'fit-content' }}>
              <Grid container columnSpacing={2}>
                {loanRequest.requiredDocuments.map((doc) => (
                  <Grid item xs="auto" display="flex" alignItems="center" key={doc.name}>
                    <img src={REQUIRED_DOCUMENTS[doc.name]} alt={doc.userInputOptions.label} />
                    <Typography marginLeft="4px" sx={{ fontSize: '0.875rem' }}>
                      {doc.userInputOptions.label}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Alert>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography sx={{
              textAlign: 'left', fontSize: '16px', fontWeight: 600, marginBottom: '24px',
            }}
            >
              {i18n.InstallmentPlanPage.selectInstallmentPlan}
            </Typography>
            <Grid container columnGap="12px" rowGap="20px">
              {installments?.map((installment) => (
                <Grid item xs="auto" key={installment.installments}>
                  <InstallmentCard
                    installment={installment}
                    selected={installment.installments === selectedInstallment?.installments}
                    onSelect={setSelectedInstallment}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }

  return <></>;
};

export default InstallmentPlanPage;
