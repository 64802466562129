/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
const i18n = {
  Login: {
    title: 'Iniciar sesión',
    description: 'Ingresá tu usuario y contraseña',
    userInput: 'Usuario',
    passInput: 'Contraseña',
    rememberMe: 'Recordar mi usuario',
    forgotPass: 'Olvidé mi contraseña',
    loginCTA: 'Iniciar sesión',
    errors: {
      E001: 'Usuario y/o contraseña incorrectos',
      E002: 'Usuario no habilitado para operar',
      E003: 'Vendedor no habilitado para operar',
      E004: 'Usuario no habilitado para operar',
      E005: 'Comercio no habilitado para operar',
      E006: 'Casa Matriz no habilitada para operar',
      E007: 'Usuario no habilitado para esta aplicación',
      E008: 'Usuario y/o contraseña incorrectos',
      E009: 'Usuario no está dado de alta en el Comercio',
      E010: 'La configuración del Usuario o Comercio es incorrecta (No existe la relación Tipo Producto / Comercio)',
      E011: 'La configuración del Usuario o Comercio es incorrecta (No existe Tipo Producto)',
      E012: 'La configuración del Usuario o Comercio es incorrecta (No existe la relación Tipo Producto / Organismo)',
      E013: 'La configuración del Usuario o Comercio es incorrecta (No existe Organismo)',
      E014: 'La configuración del Usuario o Comercio es incorrecta (No existe Línea Comercializador)',
      E015: 'La configuración del Usuario o Comercio es incorrecta (No existe Línea)',
      E016: 'La configuración del Usuario o Comercio es incorrecta (No existe la relación Línea / Organismo)',
      E017: 'La configuración del Usuario o Comercio es incorrecta (No existe Comercio Categoría)',
      EMAIL_IS_NOT_VALID: 'No tenemos registrado un email válido parar tu usuario, por favor comunicate con tu asesor comercial o Mesa de Acuerdo.',
      EXPIRED_EXECUTE_USER_PASSWORD_RESET: 'El link de restablecer contraseña expiró. Volvé a iniciar el proceso.',
      CHANGE_PASSWORD_USER_INTERNET_CHANNEL_ERROR: 'No es posible modificar o recupear la contraseña de este usuario debido a su configuración de canal internet. Contactá a tu comercial de Credicuotas',
      GENERIC_ERROR: 'Ocurrió un error mientras se procesaba la solicitud. Por favor intente nuevamente más tarde o comuniquese con Credicuotas',
    },
    reviewInfoMessage: 'Revisa los datos ingresados y volvé a intentarlo',
  },

  ForgotPassword: {
    title: [
      '¿Olvidaste tu contraseña?',
      'Revisá tu correo',
    ],
    description: [
      'Ingresá tu usuario y te enviaremos un mail con instrucciones para restrabecerla.',
      'Enviamos un mail a <b>${0}</b> para que puedas restablecer tu contraseña.',
      'Si no te llega el mail o esta no es tu casilla de correo, comunicate con tu asesor comercial o Mesa de Acuerdos.',
    ],
    userInput: 'Usuario',
    recoverCTA: 'Restablecer contraseña',
    cancelCTA: 'Cancelar',
    initSessionCTA: 'Iniciar sesión',
  },

  ExpiredPassword: {
    title: [
      'Actualizá tu contraseña',
      '¡Listo!',
    ],
    description: [
      'La nueva contraseña tiene que ser diferente a la actual, y debe contener 6 caracteres usando números y/o letras',
      'Restableciste tu contraseña con éxito',
    ],
    warningMessage: 'Tu contraseña expiró',
    newPassInput: 'Nueva contraseña',
    newPassRepeatInput: 'Repetir nueva contraseña',
    updateCTA: 'Actualizar contraseña',
    cancelCTA: 'Cancelar',
  },

  RestorePassword: {
    title: 'Contraseña restablecida',
    description: 'Te enviamos un email con la nueva contraseña para que puedas ingresar a la plataforma.',
  },

  schemaValidation: {
    user: {
      required: 'El usuario es obligatorio',
    },
    pass: {
      required: 'La contraseña es obligatoria',
    },
    newPass: {
      required: 'La nueva contraseña es obligatoria',
      minLength: 'La nueva contraseña debe ser de mínimo 6 caracteres',
      alphanumeric: 'Debe contener solo letras y/o números',
    },
    repeatNewPass: {
      required: 'La nueva contraseña es obligatoria',
      match: 'Las contraseñas no coinciden. Revisá que ambas estén escritas igual',
    },
    customerId: {
      required: 'El DNI es obligatorio',
      length: 'El DNI debe ser un número entre 6 y 9 dígitos',
    },
    product: {
      required: 'Seleccioná un producto',
    },
    subproduct: {
      required: 'Seleccioná un sub producto',
    },
    terms: {
      required: 'Seleccioná el plazo de pago',
    },
    customerTaxId: {
      required: 'Seleccioná una persona',
    },
    howMeetUs: {
      required: 'Seleccioná como nos conociste',
    },
  },

  SideMenu: {
    sections: {
      account: 'Cuenta',
    },
    options: {
      home: 'Inicio',
      newLoanRequest: 'Nueva solicitud',
      loanRequests: 'Solicitudes',
      renewers: 'Renovadores',
      liquidations: 'Liquidaciones',
      commission: 'Comisiones',
      sales: 'Panel de ventas',
      credicuotasClub: 'Club Credicuotas',
    },
  },

  QuotationPage: {
    title: 'Nueva solicitud',
    productAndSubproductSection: 'Elegí el producto y sub producto',
    productLabel: 'Producto',
    productEmptyLabel: 'Seleccionar producto',
    subproductLabel: 'Sub Producto',
    subproductEmptyLabel: 'Seleccionar sub producto',
    termsLabel: 'Plazo de Pago',
    termsEmptyLabel: 'Seleccionar plazo de pago',
    customerDataSection: 'Completá los datos de la persona',
    customerIdLabel: 'DNI',
    customerIdHint: 'Sin espacios ni puntos',
    customerTaxIdLabel: 'Seleccioná una persona',
    customerIdNotFound: 'No se encontraron personas para ese DNI',
    genderLabel: 'Genero',
    howMeetUsLabel: '¿Cómo nos conoció?',
    pepLabel: '¿Es una Persona Políticamente Expuesta?',
    pepHelp: 'Se consideran PEPs a los Funcionarios Públicos que ocupan o que ocuparon altos cargos en puestos jerárquicos, como así también a sus familiares directos.',
    soLabel: '¿Es un Sujeto Obligado?',
    soHelp: 'Un Sujeto Obligado es una persona que tiene obligaciones ante la UIF por la actividad que realiza.',
    cta: 'Continuar',
    quotationUnavailable: 'La generación de nuevas solicitudes está deshabilitada para esta configuración de comercio',
    overlayLoadingMessage: 'Estamos haciendo un cálculo personalizado para encontrar la mejor oferta',
  },

  InstallmentPlanPage: {
    title: 'Monto total disponible: <span style="color: #E72064; font-weight: 700; font-size: 24px">${0}</span>',
    ctaReload: 'Actualizar oferta',
    requiredDocumentation: 'Documentación requerida',
    debitCard: 'Tarjeta de débito',
    selectInstallmentPlan: 'Seleccioná el plan de cuotas',
  },

  StepHeader: {
    title: 'Datos de la solicitud',
    idCredit: 'Número de crédito: <b>${0}<b/>',
    product: 'Producto: <b>${0}</b>',
    customerName: 'Nombre y apellido: <b>${0}</b>',
    customerTaxId: 'CUIT/CUIL: <b>${0}</b>',
    requestedAmount: 'Solicitado a liquidar: <b>${0}</b>',
    cancelAmount: 'Cancelando: <b>${0}</b>',
    paymentPlan: 'Plan de pago: <b>${0} cuotas de ${1}</b>',
    vehicle: 'Vehículo: <b>${0} (${1})</b>',
    advanceAmount: 'Monto anticipo: <b>${0}</b>',
  },

  InstallmentCard: {
    installments: '${0} cuotas',
    cadecom: 'Beneficio CADECOM',
    discount: '<b>${0}%</b> más barato',
  },

  CustomerData: {
    comboBoxDefaulOption: 'Seleccione una opción',
  },

  // loading svg
  loadingSVG1: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG2: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG3: 'M55.2 46.9c-.4-.8-.9-1.4-1.6-1.9-.7-.5-1.5-.9-2.3-1.2-.9-.3-1.8-.5-2.7-.6l-3.8-.5c-.9-.1-1.6-.3-2-.5-.4-.2-.7-.5-.7-1s.2-.9.7-1.2c.5-.3 1.4-.4 2.6-.4 1.1 0 2.1.1 3 .3.9.2 1.6.4 2.3.6.6.2 1.1.4 1.5.6.4.2.6.4.7.5.1 0 .2-.1.4-.3.2-.1.4-.3.7-.6l.6-.9c.2-.3.3-.7.3-1.2 0-.6-.2-1.1-.5-1.5-.3-.4-.7-.7-1.3-1-.9-.5-2-.8-3.3-1-.4-.1-.8-.1-1.3-.2V34c0-.9-.8-1.7-1.7-1.7h-3c-.9 0-1.7.8-1.7 1.7v1c-.5.1-.9.2-1.3.3-1.3.4-2.3.9-3.1 1.5-.8.7-1.4 1.4-1.8 2.3-.4.9-.6 1.8-.6 2.9 0 1.2.2 2.2.7 3.1.4.8 1 1.5 1.8 2s1.7.9 2.7 1.2c1 .3 2 .5 3.1.6l2.2.2c1 .1 1.8.3 2.3.5.5.2.7.6.7 1.2 0 .5-.3.9-.9 1.1-.6.2-1.5.3-2.6.3-1 0-2-.1-2.9-.2-.9-.1-1.7-.3-2.4-.5s-1.3-.4-1.8-.7c-.5-.2-.9-.4-1.1-.6l-2.4 4.3c.2.2.6.4 1.2.7.6.3 1.3.6 2.2.9.9.3 2 .5 3.3.8.2 0 .4.1.7.1v.9c0 .9.8 1.7 1.7 1.7h3c.9 0 1.7-.8 1.7-1.7V57c1.9-.3 3.5-.9 4.7-1.8 1.8-1.3 2.7-3.1 2.7-5.5 0-1.1-.3-2.1-.7-2.8z',
};

export default i18n;
