import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getDocuments, getLoanRequest, getRequiredInputFields } from '../../api/workflowApiService';
import { PLLLA_DEBIT_CARD_FIELD_NAME, PTLLA_DEBIT_CARD } from '../../constants/commerceContants';
import i18n from '../../common/i18n';
import { getApiErrorCode, getApiErrorMessage } from '../../services/errorApiService';
import { ERROR_CODE_SAPP_NO_LINES } from '../../constants/apiErrorCodeConstants';
import { useToaster } from '../../contexts/ToasterContext';

const useInstallmentPlanController = () => {
  const { showToast } = useToaster();
  const { hash } = useParams();

  const [globalLoading, setGlobalLoading] = useState(false);
  const [loanRequest, setLoanRequest] = useState();
  const [installments, setInstallments] = useState();
  const [selectedInstallment, setSelectedInstallment] = useState();

  const getInstallments = () => {
    const installmentsAux = [
      {
        installments: 36,
        amount: 92375.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 92375.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 30,
        amount: 98436.00,
        amountOriginalLine: 128018.00, // TODO: este campo en si no existe, se obtiene de installmentsWithOriginalLine el campo amount.
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 98436.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 24,
        amount: 106015.00,
        amountOriginalLine: 131377.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 106015.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 18,
        amount: 119833.00,
        amountOriginalLine: 136891.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 119833.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 15,
        amount: 130018.00,
        amountOriginalLine: 144649.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 130018.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 12,
        amount: 146164.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 146164.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 9,
        amount: 170415.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 170415.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 6,
        amount: 221594.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 221594.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
    ];

    setInstallments(installmentsAux);
  };

  const init = async () => {
    try {
      setGlobalLoading(true);
      const { data: lr } = await getLoanRequest(hash);
      const [{ data: requiredDocuments }, { data: requiredInputFields }] = await Promise.all([getDocuments(hash), getRequiredInputFields(hash)]);

      const cardInput = requiredInputFields.find((field) => field.name === PTLLA_DEBIT_CARD);
      if (cardInput) {
        cardInput.userInputOptions.label = i18n.InstallmentPlanPage.debitCard;
        cardInput.name = PLLLA_DEBIT_CARD_FIELD_NAME;
        requiredDocuments.push(cardInput);
      }
      lr.requiredDocuments = requiredDocuments;
      setLoanRequest(lr);
      // TODO: mock installments
      getInstallments();
    } catch (error) {
      if (getApiErrorCode(error) === ERROR_CODE_SAPP_NO_LINES) {
        // TODO: casuistica de pantalla cuando no tiene lineas
      } else {
        showToast(getApiErrorMessage(error), 'error');
      }
    } finally {
      setGlobalLoading(false);
    }
  };

  const doReload = () => {
    init();
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    loanRequest,
    doReload,
    installments,
    selectedInstallment,
    setSelectedInstallment,
  };
};

export default useInstallmentPlanController;
