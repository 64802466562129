import { Card as VulpinoCard } from '@doit/pcnt-react-ui-library';
import { styled } from '@mui/material';

const Card = styled(VulpinoCard)(() => ({
  margin: '20px',
  paddingTop: '0px',
  paddingLeft: '8px',
  paddingRight: '8px',
  paddingBottom: '16px',
  border: '1px solid #E0E0E0',
  boxShadow: '0px 5px 15px 0px #0000001F',
}));

export default Card;
