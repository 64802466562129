import { createTheme } from '@mui/material/styles';

const palette = {
  primary: {
    main: '#E72064',
  },
  secondary: {
    main: '#26C6DA',
  },
  success: {
    main: '#02C66A',
  },
  error: {
    main: '#FB1531',
  },
  warning: {
    main: '#F5A623',
  },
  info: {
    main: '#3988FF',
  },
  grey: {
    main: '#424242',
    contrastText: '#fff',
  },
  text: {
    primary: '#424242',
  },
};

const Theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '32px',
    },
    h2: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    list: {
      fontFamily: 'Open Sans,sans-serif',
    },
    p: {
      fontFamily: 'Open Sans,sans-serif',
    },
    h3: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h4: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.2rem',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.2rem',
      fontWeight: 400,
    },
    h6: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
    },
    button: {
      fontFamily: 'Open Sans,sans-serif',
      fontWeight: 700,
      textTransform: 'none',
    },
    fontFamily: 'Open Sans,sans-serif',
  },
  palette,
  components: {
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: '700',
          textAlign: 'left',
          fontSize: '0.875rem',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: '700',
          borderRadius: '50px',
        },
        outlined: {
          border: '2px solid',
          '&:hover': {
            border: '2px solid',
          },
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: '1rem',
      },
      styleOverrides: {
        colorPrimary: {
          color: palette.primary.main,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            color: 'black',
            backgroundColor: 'light gray',
            cursor: 'default',
          },
          '&$selected:hover': {
            color: 'black',
            backgroundColor: '#d6d6d6',
            cursor: 'default',
          },
        },
      },
    },
    MuiSelect: {
      selectmenu: {
        whiteSpace: 'normal',
      },
      styleOverrides: {
        root: {
          textAlign: 'left',
        },
      },
    },
    MuiTableCell: {
      root: {
        '&:lastChild': { paddingRight: '0px' },
      },
      sizeSmall: {
        padding: '0px',
        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
    MuiAccordion: {
      root: {
        border: '1px',
      },
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: '#e72064',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: '#4A4A4A',
          '&$disabled': {
            color: '#9B9B9B',
          },
        },
      },
    },
    // texto ingresado
    MuiInputBase: {
      input: {
        '&$disabled': {
          color: '#444444',
        },
      },
    },
    // fin estilos personalizados de input
  },
  button: {
    card: {
      fontFamily: 'Poppins,sans-serif',
    },
  },
  body: {
    backgroundColor: 'white',
  },
});

export default Theme;
