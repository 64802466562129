import Axios from 'axios';
import { getEnv, CREDICUOTAS_COMMERCE_BACKEND_URL } from './env';

const urlBackend = getEnv(CREDICUOTAS_COMMERCE_BACKEND_URL);

export const instance = Axios.create({
  timeout: 60000,
  baseURL: `${urlBackend}`,
  withCredentials: true,
  headers: { 'content-type': 'application/json;charset=UTF-8' },
});

export const fetch = (config) => instance.request(config)
  .then((res) => res)
  .catch((err) => {
    throw err;
  });

export const instanceMultipart = Axios.create({
  timeout: 60000,
  baseURL: `${urlBackend}`,
  withCredentials: true,
  headers: { 'content-type': 'multipart/form-data' },
});

export const fetchMultipart = (config) => instanceMultipart.request(config)
  .then((res) => res)
  .catch((err) => {
    throw err;
  });

export default fetch;
