import { fetch } from './axiosInstance';

export const resolveIdentification = (customerId) =>
  fetch({
    url: '/workflow/resolvecustomeridentification',
    method: 'get',
    params: {
      dni: customerId,
    },
  });

export const getContactOrigins = () =>
  fetch({
    url: '/workflow/contactorigins',
    method: 'get',
  });

export const checkExistingPenLoanRequest = (startWorkflowDto) =>
  fetch({
    url: '/workflow/checkExistingPreLoanRequest',
    method: 'post',
    data: startWorkflowDto,
  });

export const startWorkflow = (startWorkflowDto) =>
  fetch({
    url: '/workflow/start',
    method: 'post',
    data: startWorkflowDto,
  });

export const getLoanRequest = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}`,
    method: 'get',
  });

export const getDocuments = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/documents`,
    method: 'get',
  });

export const getRequiredInputFields = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/requiredInputFields`,
    method: 'get',
  });

export const getUserInputFields = (hash) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/userinputs`,
    method: 'get',
  });

export const resolveConditionalComboData = (hash, varName, conditioningValue) =>
  fetch({
    url: `/workflow/loanRequest/${hash}/userinput/combodata/${varName}/${conditioningValue}`,
    method: 'get',
  });
