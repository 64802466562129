import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import TextInputControlled from '../../../components/commons/TextInputControlled';

const CustomerDataInputText = ({ input }) => (
  <>
    <Grid item xs="auto">
      <TextInputControlled
        name={input.name}
        label={input.userInputOptions.label}
        defaultValue={input.userInputOptions.defaultValue}
        variant="outlined"
        helperText={input.userInputOptions.hint}
        mandatory={input.userInputOptions.required}
      />
    </Grid>
    {input.userInputOptions.verification !== 'NONE' && (
      <>
        <Grid item xs="auto">
          <Button variant="secondary">{ input.name === 'PtllaMail' ? 'Validar email' : 'Validar celular' }</Button>
        </Grid>
        <Grid item xs="auto">
          <TextInputControlled
            name="code"
            label="Código"
            variant="outlined"
            mandatory
          />
        </Grid>
      </>
    )}
  </>
);

CustomerDataInputText.propTypes = {
  input: PropTypes.shape().isRequired,
};

export default CustomerDataInputText;
