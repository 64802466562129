import { Fragment } from 'react';
import { Button, CardActions, CardContent } from '@doit/pcnt-react-ui-library';
import { Divider, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from '../../components/commons/Card';
import useCustomerDataController from '../../hooks/controllers/useCustomerDataController';
import StepHeader from './components/StepHeader';
import Loading from '../../components/commons/Loading';
import i18n from '../../common/i18n';
import { customerDataSchemaValidation } from '../../forms/customerDataSchemaValidations';
import CustomerDataInputText from './components/CustomerDataInputText';
import CustomerDataComboBox from './components/CustomerDataComboBox';
import CustomerDataTypeAhead from './components/CustomerDataTypeAhead';

const CustomerDataPage = () => {
  const {
    globalLoading,
    loanRequest,
    inputGroups,
    resolveToShowConditional,
    resolveToShowUserInputIfAlreadyHasValue,
    onSelectOption,
    // mustLoadDebitCardInfo,
    // hasDebitCardValid,
    // userFilled,
  } = useCustomerDataController();

  const methods = useForm({
    resolver: yupResolver(customerDataSchemaValidation(inputGroups)),
  });

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <CardContent>
          <StepHeader loanRequest={loanRequest} />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit((data) => { console.log(data); })}>
              {inputGroups.map((inputGroup, index) => (
                <Fragment key={inputGroup.name}>
                  <Typography align="left" sx={{ marginBottom: '32px', marginTop: index !== 0 ? '32px' : '0px' }}>
                    <b>{inputGroup.name}</b>
                  </Typography>
                  <Grid container key={inputGroup.name} columnGap="24px" rowGap="32px">
                    {inputGroup.variables.map((input) => (
                      <Fragment key={input.name}>
                        {input.userInputOptions.type === 'TEXT' && resolveToShowConditional(input) && (
                          <CustomerDataInputText input={input} />
                        )}
                        {input.userInputOptions.type === 'CHECKBOX' && resolveToShowConditional(input) && (
                          <Grid item xs="auto">
                            <Typography>{`${input.userInputOptions.label} (CHECKBOX)`}</Typography>
                          </Grid>
                        )}
                        {(input.userInputOptions.type === 'COMBO' || input.userInputOptions.type === 'RADIO')
                        && resolveToShowConditional(input) && resolveToShowUserInputIfAlreadyHasValue(input) && (
                          <CustomerDataComboBox
                            input={input}
                            inputGroup={inputGroup}
                            index={index}
                            methods={methods}
                            onSelectOption={onSelectOption}
                          />
                        )}
                        {input.userInputOptions.type === 'TYPEAHEAD' && resolveToShowConditional(input) && (
                          <CustomerDataTypeAhead input={input} methods={methods} />
                        )}
                        {input.userInputOptions.type === 'DATE' && resolveToShowConditional(input) && (
                          <Grid item xs="auto">
                            <Typography>{`${input.userInputOptions.label} (DATE)`}</Typography>
                          </Grid>
                        )}
                        {input.userInputOptions.type === 'TEXT_AREA' && resolveToShowConditional(input) && (
                          <Grid item xs="auto">
                            <Typography>{`${input.userInputOptions.label} (TEXT_AREA)`}</Typography>
                          </Grid>
                        )}
                        {input.userInputOptions.type === 'CARD' && resolveToShowConditional(input) && (
                          <Grid item xs="auto">
                            <Typography>{`${input.userInputOptions.label} (CARD)`}</Typography>
                          </Grid>
                        )}
                      </Fragment>
                    ))}
                  </Grid>
                  {index < inputGroups.length - 1 && <Divider sx={{ marginTop: '32px' }} />}
                </Fragment>
              ))}
              <CardActions>
                <Button type="submit" color="grey">{i18n.QuotationPage.cta}</Button>
              </CardActions>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </>
  );
};

export default CustomerDataPage;
